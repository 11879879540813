import React from "react";
import { Routes, Route } from "react-router-dom";
import PrivateRouters from "Routers/PrivateRouter";
import PublicRouters from "Routers/PublicRouter";
import { PublicroutesArray, PrivateroutesArray } from "./routes";
import Layout from "./Layout";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-select2-wrapper/css/select2.css";

function App() {
  return (
      <Routes>
          {/* Render public routes */}
          {PublicroutesArray.map(({ component: Component, path }, key) => {
              return (
                  <Route
                      path={path}
                      element={
                          <PublicRouters>
                              <Component />
                          </PublicRouters>
                      }
                      key={key}
                  />
              );
          })}

          {/* Render private routes */}
          {PrivateroutesArray?.map(({ component: Component, path, title }, key) => (
              <Route
                  path={path}
                  element={
                      <PrivateRouters>
                          {/* Wrap the component in a layout */}
                          <Layout title={title}>
                              <Component />
                          </Layout>
                      </PrivateRouters>
                  }
                  key={key}
              />
          ))}
      </Routes>

  );
}

export default App;
