let init = {
  data: {},
  open: false,
  snackBarData: {
    open: false,
    message: "",
    variant: "",
    title:""
  }
};

const errorReducer = (state = init, { type, payload }) => {
  switch (type) {
    case "UPDATE_ERROR_MODAL": {
      return {
        ...state,
        ...payload,
      };
    }
    case "UPDATE_SNACKBAR_STATE": {
      return {
        ...state,
        snackBarData:{...payload}
      }
    }
    default: {
      return state;
    }
  }
};

export default errorReducer;
