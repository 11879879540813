
const PopComponent = {
    ForgotPassword: require('../Components/Modal/ForgotPassword').default,
    CommonPop: require('../Components/Modal/CustomPopup').default,
    DeleteCommonModal: require('../Components/Modal/DeleteCommonModal').default,
    AddCompanyLogo: require('../Pages/CompanyLogo/AddCompanyLogo').default,
    //========================================= User Module ============================================================
    BlockUser: require('../Components/Modal/User/BlockUser').default,
    ViewTransitionHistory: require('../Components/Modal/User/ViewTransitionHistory').default,
    UpdateCashAndBonus: require('../Components/Modal/User/UpdateCashAndBonus').default,
    AddCoinPopup: require('../Components/Modal/User/AddCoinPopup').default,
    ViewOldUserMobileNumber: require('../Pages/Users/UpdateUserMobileNumber/ViewOldUserMobileNumber').default,
    ApprovedMobileNumber: require('../Pages/Users/UpdateUserMobileNumber/ApprovedMobileNumber').default,
    RejectUserMobileNumber: require('../Pages/Users/UpdateUserMobileNumber/RejectUserMobileNumber').default,
    DocumentOpenPopup: require('../Components/Modal/DocumentOpenPopup').default,
    UserBlockListView: require('../Pages/Users/BlockUserList/UserBlockListView').default,
    UnblockUserPopup: require('../Pages/Users/BlockUserList/UnblockUserPopup').default,
    ViewReportedUserList: require('../Components/Modal/User/ViewReportedUserList').default,
    ExportFilePopup: require('../Pages/Users/AllUserList/ExportFilePopup').default,
    AddUserNote: require('../Pages/Users/UserTab/UserNote/AddUserNote').default,
    AddConfig: require('../Pages/Users/ReportedUser/ReportConfig/AddConfig').default,
    //============================================= Admin Module Popup ================================================
    AddAdminUserList: require('../Components/Modal/AdminUser/AddAdminUserList').default,
    ActiveUserModal: require('../Components/Modal/AdminUser/ActiveUserModal').default,
    AddSubAdminPopup: require('../Pages/SubAdminUser/AddSubAdminPopup').default,
    ActivateDeactivateSubAdmin: require('../Pages/SubAdminUser/ActivateDeactivateSubAdmin').default,
    EditSubAdminPopup: require('../Pages/SubAdminUser/EditSubAdminPopup').default,
    //=========================================== Game Page ===========================================================
    RejectedPopup: require('../Components/Modal/GamePopup/RejectedPopup').default,
    ApprovedPendingPopup: require('../Components/Modal/GamePopup/ApprovedPendingPopup').default,
    AddNewGame: require('../Components/Modal/GamePopup/UpdateGame').default,
    ActiveDeactivatedPopup: require('../Components/Modal/GamePopup/ActiveDeactivatedPopup').default,
    ViewRejectedComment: require('../Components/Modal/GamePopup/ViewRejectedComment').default,
    UpdateGame: require('../Components/Modal/GamePopup/UpdateGame').default,
    ArchivedGamePopup: require('../Components/Modal/GamePopup/ArchivedGamePopup').default,
    AddGameMode: require('../Pages/Games/GameDetails/GameTabDetails/GameModeTab/AddGameMode').default,
    CreateLobby: require('../Pages/Games/GameDetails/GameTabDetails/LobbyTab/CreateLobby').default,
    UpdateLobby: require('../Pages/Games/GameDetails/GameTabDetails/LobbyTab/UpdateLobby').default,
    ActiveDeactivateLobby: require('../Pages/Games/GameDetails/GameTabDetails/LobbyTab/ActiveDeactivateLobby').default,
    DeleteLobby: require('../Pages/Games/GameDetails/GameTabDetails/LobbyTab/DeleteLobby').default,
    ViewLobbyDetails: require('../Pages/Games/GameDetails/GameTabDetails/LobbyTab/ViewLobbyDetails').default,
    CreateRecurringTournament: require('../Pages/Games/GameDetails/GameTabDetails/TournamentTab/RecurringTournament/CreateRecurringTournament').default,
    UpdateGameCurrency: require('../Pages/Games/GameDetails/GameTabDetails/GameCurrencyTab/UpdateGameCurrency').default,
    AddGameBuilds: require('../Pages/Games/GameDetails/GameTabDetails/GameBuildsTab/AddGameBuilds').default,
    ViewReleaseGuide: require('../Pages/Games/GameDetails/GameTabDetails/GameBuildsTab/ViewReleaseGuide').default,
    UpdateGameBuild: require('../Pages/Games/GameDetails/GameTabDetails/GameBuildsTab/UpdateGameBuild').default,
    DeleteHowToPlay: require('../Pages/Games/GameDetails/GameTabDetails/HowToPlayGameTab/DeleteHowToPlay').default,
    AddNumberOfDecksPopup: require('../Pages/Games/GameDetails/GameTabDetails/GameNumberOfDecksTab/AddNumberOfDecksPopup').default,
    AddNumberOfPlayerPopup: require('../Pages/Games/GameDetails/GameTabDetails/GameNumberOfPlayer/AddNumberOfPlayerPopup').default,
    AddGameModeConfigList: require('../Pages/Games/GameDetails/GameTabDetails/GameModeTab/GameModeConfig/AddGameModeConfigList').default,
    AddDummyPlayer: require('../Pages/Games/GameDetails/GameTabDetails/GameDummyPlayerTab/AddDummyPlayer').default,
    UpdateLeaderboardRules: require('../Pages/Games/GameDetails/GameTabDetails/GameLeaderboard/MonthlyGameLeaderboardConfigTab/GameLeaderboardRules/UpdateLeaderboardRules').default,
    ViewHowToPlayPopup: require('../Pages/Games/GameDetails/GameTabDetails/HowToPlayGameTab/ViewHowToPlayPopup').default,
    viewPlayingScoreBoardPopup: require('../Pages/Games/GameDetails/GameTabDetails/PlayingTracking/ViewPlayingScoreBoardPopup').default,
    activeDeactivateNumberOfPlayer: require('../Pages/Games/GameDetails/GameTabDetails/GameNumberOfPlayer/ActiveDeactivateNumberOfPlayer').default,
    createHowToPlayGame: require('../Pages/Games/GameDetails/GameTabDetails/HowToPlayGameTab/CreateHowToPlayGame').default,
    editHowToPlayRules: require('../Pages/Games/GameDetails/GameTabDetails/HowToPlayGameTab/EditHowToPlayRules').default,
    AddGenrePopup: require('../Components/Modal/GamePopup/AddGenrePopup').default,
    AddMonthlyGameLeaderboardRank: require('../Pages/Games/GameDetails/GameTabDetails/GameLeaderboard/MonthlyGameLeaderboardConfigTab/MonthlyGameLeaderboardRankTab/AddMonthlyGameLeaderboardRank').default,
    // AddGameLeaderboardBonusAmount: require('../Pages/Games/GameDetails/GameTabDetails/GameLeaderboard/MonthlyGameLeaderboardConfigTab/MonthlyGameLeaderboardRankTab/GameLeaderboardBonusAmount/AddGameLeaderboardBonusAmount').default,
    // AddGameLeaderboardBonusReleaseDate: require('../Pages/Games/GameDetails/GameTabDetails/GameLeaderboard/MonthlyGameLeaderboardConfigTab/MonthlyGameLeaderboardRankTab/GameLeaderboardBonusReleaseDate/AddGameLeaderboardBonusReleaseDate').default,
    AddGameLeaderboardRules: require('../Pages/Games/GameDetails/GameTabDetails/GameLeaderboard/MonthlyGameLeaderboardConfigTab/GameLeaderboardRules/AddGameLeaderboardRules').default,
    ViewGameHistoryPopup: require('../Pages/Games/GameHistory/ViewGameHistoryPopup').default,
    RefundGameHistory: require('../Pages/Games/GameHistory/RefundGameHistory').default,
    ViewDeductedEntryFee: require('../Pages/Games/GameHistory/ViewDeductedEntryFee').default,
    ViewPlayerRecord: require('../Pages/Games/GameDetails/GameTabDetails/PlayerRecord/ViewPlayerRecord').default,
    AddModeWiseGameSeverLink: require('../Pages/Games/GameDetails/GameTabDetails/GameSetupTab/BasicStepsSetup/GameInfoDetails/GameModeWiseGameSeverLink/AddModeWiseGameSeverLink').default,
    //=================================================== Bonus Module =================================
    AddDailyWheelBonusPop: require('../Pages/Bonus/DailyWheelBonus/AddDailyWheelBonusPop').default,
    ViewReferAndEarnList: require('../Pages/Bonus/ReferAndEarn/ReferAndEarnTab/ViewReferAndEarnList').default,
    AddReferAndEarnList: require('../Pages/Bonus/ReferAndEarn/ReferAndEarnTab/AddReferAndEarnList').default,
    ActivateDeactivateEarnPopup: require('../Pages/Bonus/ReferAndEarn/ReferAndEarnTab/ActivateDeactivateEarnPopup').default,
    AddMonthlyReferAndEarnConfigPopup: require('../Pages/Bonus/ReferAndEarn/ReferAndEarnMonthlyConfig/AddMonthlyReferAndEarnConfigPopup').default,
    AddReferAndEarnMonthly: require('../Pages/Bonus/ReferAndEarn/ReferAndEarnMonthlyTab/AddReferAndEarnMonthly').default,
    AddBonusReleaseDate: require('../Pages/Bonus/ReferAndEarn/ReferAndEarnMonthlyTab/BonusReleaseDate/AddBonusReleaseDate').default,
    AddMonthlyBonusAmount: require('../Pages/Bonus/ReferAndEarn/ReferAndEarnMonthlyTab/MonthlyBonusAmount/AddMonthlyBonusAmount').default,
    AddDailyWheelBonusConfig: require('../Pages/Bonus/DailyWheelBonus/DailyWheelBonusConfig/AddDailyWheelBonusConfig').default,
    AddDailyWheelBonusType: require('../Pages/Bonus/DailyWheelBonus/DailyWheelBonusConfigTab/DailyWheelBonusType/AddDailyWheelBonusType').default,
    AddReferAndEarnShareConfig: require('../Pages/Bonus/ReferAndEarn/ReferAndEarnShareConfig/AddReferAndEarnShareConfig').default,
    AddDailyBonusPopUp: require('../Pages/Bonus/DailyWheelBonus/DailyBonus/AddDailyBonusPopUp').default,

    //======================================== Master ==========================================================
    AddLobbyLabel: require('../Pages/Master/LobbyLabel/AddLobbyLabel').default,
    AddReportReason: require('../Pages/Master/ReportReason/AddReportReason').default,
    UpdateWalletRules: require('../Pages/Master/Document/WalletRules/UpdateWalletRules').default,
    ActiveDeactivateCategory: require('../Pages/Master/GenreCategory/ActiveDeactiveCategory').default,
    UpdateFairPlayPolicy: require('../Pages/Master/Document/FairPlayPolicy/UpdateFairPlayPolicy').default,
    UpdateLegalPolicyPop: require('../Pages/Master/Document/LegalPolicy/UpdateLegalPolicyPop').default,
    UpdatePrivacyPolicy: require('../Pages/Master/Document/PrivacyPolicy/UpdatePrivacyPolicy').default,
    UpdateTermsAndCondition: require('../Pages/Master/Document/TermsAndConditionsPolicy/UpdateTermsAndCondition').default,
    AddGenreTypePopup: require('../Pages/Master/GenreCategory/GenreTypeListTab/AddGenreTypePopup').default,
    AddBot: require('../Pages/Master/BotModule/AddBot').default,
    UpdateReferAndEarnRules: require('../Pages/Master/Document/ReferAndEarnRules/UpdateReferAndEarnRules').default,
    AddMGPOnlinePlayers: require('../Pages/Master/MGPOnlinePlayer/AddMGPOnlinePlayers').default,
    AddGameModeDesignConfig: require('../Pages/Master/GameModeDesignConfig/AddGameModeDesignConfig').default,
    AddAvatarPopup: require('../Pages/Master/Avatar/AddAvatarPopup').default,
    UpdateRefundPolicy: require('../Pages/Master/Document/RefundPolicy/UpdateRefundPolicy').default,
    UpdateUserDeleteAccountPolicy: require('../Pages/Master/Document/DeleteUserPolicy/UpdateUserDeleteAccountPolicy').default,
    AddDeleteUserAccountTermsCondition: require('../Pages/Master/Document/DeleteUserPolicy/DeleteTermCondition/AddDeleteUserAccountTermsCondition').default,
    AddSplashScreen: require('../Pages/Master/SplashScreen/AddSplashScreen').default,
    AddTagLineSplashScreen: require('../Pages/Master/SplashScreen/TagLineTable/AddTagLineSplashScreen').default,
    AddSplashScreenPopUp: require('../Pages/Master/SplashScreen/SplashScreenImage/AddSplashScreenPopUp').default,

    //============================================= Marketing Module Popup ================================================
    CreateAds: require('../Pages/Marketing/InternalAdsList/CreateAds').default,
    AddOffer: require('../Pages/Marketing/OfferTab/Offer/AddOffer').default,
    AddOfferPackagePopUp: require('../Pages/Marketing/OfferTab/OfferPackage/AddOfferPackagePopUp').default,
    ActiveDeactivatePopup: require('../Pages/Marketing/InternalAdsList/ActiveDeactivatePopup').default,
    AddUserTypeNotification: require('../Pages/Marketing/NotificationModule/UserTypeNotification/AddUserTypeNotification').default,
    AddNotificationPopup: require('../Pages/Marketing/NotificationModule/AllTypeNotification/AddNotificationPopup').default,
    AddOfferImages: require('../Pages/Marketing/OfferTab/OfferImage/AddOfferImages').default,
    //============================================= Help And Support Module Popup ================================================
    AddTicketTypePop: require('../Pages/HelpAndSupport/Ticket/AddTicketTypePop').default,
    AddEmailPopup: require('../Pages/HelpAndSupport/EmailModule/AddEmailPopup').default,
    AddCustomerCare: require('../Pages/HelpAndSupport/CustomerCareTab/AddCustomerCare').default,
    AddTicketsVideo: require('../Pages/HelpAndSupport/TicketsVideoTab/AddTicketsVideo').default,
    ViewVideoImage: require('../Pages/HelpAndSupport/TicketsVideoTab/ViewVideoImage').default,
    AddFAQPopup: require('../Pages/HelpAndSupport/FAQQuestionTab/AddFAQPopup').default,
    AddFAQTitle: require('../Pages/HelpAndSupport/FAQQuestionTab/FAQTitle/AddFAQTitle').default,
    AddFAQTypePopup: require('../Pages/HelpAndSupport/FAQQuestionTab/FAQType/AddFAQTypePopup').default,
    AddWhatsAppMobileNumberPopup: require('../Pages/HelpAndSupport/WhatsAppSupportTab/AddWhatsAppMobileNumberPopup').default,

    AddTicketTypeConfig : require('../Pages/HelpAndSupport/TicketsVideoTab/VideoAndImage/SelectTicketTypeConfig/AddTicketTypeConfig').default,
    AddHelpDeskTicketVideo : require('../Pages/HelpAndSupport/TicketsVideoTab/VideoAndImage/VideoType/AddHelpDeskTicketVideo').default,
    AddHelpDeskHeaderImage : require('../Pages/HelpAndSupport/TicketsVideoTab/VideoAndImage/ImageType/AddHelpDeskHeaderImage').default,
    //============================================= MGP Release Module Popup ================================================
    AddMGPRelease: require('../Pages/MGPRelease/AddMGPRelease').default,
    HaltRollOutPopup: require('../Pages/MGPRelease/haltRollOutPopup').default,
    ActiveDeactivateMGPRelease: require('../Pages/MGPRelease/ActiveDeactivateMGPRelease').default,
    UpdateRollOutPopup: require('../Pages/MGPRelease/MGPReleaseTab/UpdateRollOutPopup').default,
    //============================================= Settings Module Popup ==============================================
    countryDisplayPopup: require('../Pages/Settings/CountryDisplayPopup').default,
    UpdateSettingConfig: require('../Pages/Settings/Config/UpdateSettingConfig').default,
    AddPaymentGateway: require('../Pages/Settings/PaymentGateway/AddPaymentGateway').default,
    AddMaintenancePopup: require('../Pages/Settings/Maintenance/AddMaintenancePopup').default,
    AddErrorCodePopUp: require('../Pages/Settings/ErrorCode/AddErrorCodePopUp').default,
    //============================================= PopularGames Module Popup ==========================================
    AddUpcomingGamesPopup: require('../Pages/PopularGames/UpcomingGames/AddUpcomingGamesPopup').default,
    CreateCustomizeGameList: require('../Pages/PopularGames/PopularGameFirstDesign/CreateCustomizeGameList').default,
    CreateCustomizeLiveGameList:require('../Pages/PopularGames/LiveGames/CreateCustomizeLiveGameList').default,
    DeletePopularGame:require('../Pages/PopularGames/PopularGameFirstDesign/DeletePopularGame').default,
    //============================================= Revenue Module Popup ================================================

    AddRestrictGeo: require('../Pages/Settings/RestrictGeo/AddRestrictGeo').default,

    AddInApp: require('../Pages/Master/InApp/AddInApp').default,
    //============================================= Design Module Popup ================================================
    AddTypeConfig : require('../Pages/Design/LoginScreen/LogoAndPrivacyPolicy/LogoAndImage/SelectLoginScreenTypeConfig/AddTypeConfig').default,
    AddLogo : require('../Pages/Design/LoginScreen/LogoAndPrivacyPolicy/LogoAndImage/LogoType/AddLogo').default,
    AddImages : require('../Pages/Design/LoginScreen/LogoAndPrivacyPolicy/LogoAndImage/ImageType/AddImages').default,
    AddFooterPopup : require("../Pages/Design/LoginScreen/FooterIcon/AddFooterPopup").default,
    TitleEditPopUp : require("../Pages/Design/ReferAndEarn/ReferAndEarnTitle/TitleEditPopUp").default,
    AddReferAndEarnDetailPopUp : require("../Pages/Design/ReferAndEarn/ReferAndEarnSteps/AddReferAndEarnDetailPopUp").default,
    AddHomeScreenFooterIconPopup : require("../Pages/Design/HomeScreen/HomeScreenFooterIcon/AddHomeScreenFooterIconPopup").default,
    AddHomeScreenLogo : require("../Pages/Design/HomeScreen/HomeScreenLogo/AddHomeScreenLogo").default,
    AddLogoPrivacyPolicy : require("../Pages/Design/LoginScreen/LogoAndPrivacyPolicy/LogoPrivacyPolicy/AddLogoPrivacyPolicy").default,

};

export default PopComponent;

