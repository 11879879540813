import { makeStyles, withStyles } from "@material-ui/core/styles";
import InputBase from "@material-ui/core/InputBase";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import * as React from "react";
import { useEffect } from "react";

const useStyles = makeStyles((theme) => ({
  margin: {
    minWidth: "100%",
    margin: "0",
  },
}));

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 14,
    padding: "15px 26px 15px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#1976d2",
      outlineWidth: "1px",
      boxShadow: "none",
      outline: "1px solid #1976d2",
    },
  },
  svg: {
    right: "11px",
  },
}))(InputBase);

const PackageIdDropdown = ({
  options,
  name,
  filterData,
  setFilterData,
  discount,
}) => {
  const classes = useStyles();

  const handleChange = (e) => {
    const selectedPackage = options.find((item) => item._id === e.target.value);

    if (selectedPackage) {
      const discountPercentage = (discount / 100) * selectedPackage.offerBonus;
      setFilterData({
        ...filterData,
        [name]: e.target.value,
        // offerBonus: +selectedPackage.offerBonus + discountPercentage,
        offerBonus: discountPercentage,
      });
    }
  };

  useEffect(() => {
    const selectedPackage = options?.find((item) => item._id === filterData[name]);

    if (selectedPackage) {
      const discountPercentage = (discount / 100) * selectedPackage.offerBonus;
      setFilterData({
        ...filterData,
        // offerBonus: +selectedPackage.offerBonus + discountPercentage,
        offerBonus:  discountPercentage,
      });
    }
  }, [discount, filterData[name]]);

  return (
    <div className={"filter_export_details_dropDown"}>
      <FormControl className={classes.margin}>
        <Select
          name={name}
          value={filterData[name]}
          onChange={handleChange}
          input={<BootstrapInput />}
          className={"filter_dropdown_list"}
        >
          {options?.map((item, i) => (
            <MenuItem
              className={"filter_dropdown_list"}
              key={i}
              value={item._id}
            >
              {item?.gameName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default PackageIdDropdown;
