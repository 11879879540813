import React, { useCallback, useEffect, useRef, useState } from "react";
import { Box } from "@mui/material";
import FilledButton from "../../../FileButton";
import { useDispatch } from "react-redux";
import SimpleReactValidator from "simple-react-validator";
import CategoryListDropdown from "./CategoryListDropdown";
import {helpTicketTypeArr} from "../../../../utils";
import { registerAdminUser, updateAdminUserList} from "../../../../Redux/AdminUser/action";
import {getLeaderboardGameList} from "../../../../Redux/Bonus/action";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 750,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: "5px",
};

const AddAdminUserList = ({ modalValue, handleOpenModal, redirectApiHandler }) => {
    const dispatch = useDispatch();
    const [loader, setLoader] = useState(false);
    const simpleValidator = useRef(
        new SimpleReactValidator({
          validators: {
            email: {
              message: "The email must be a valid email address.",
              rule: (val, params, validator) => {
                return validator.helpers.testRegex(
                  val,
                  /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
                );
              },
              required: true, // Make email required
            },
            phoneNumber: {
              message: "The phone number must be valid.",
              rule: (val, params, validator) => {
                // Make phone number optional by returning true if it's empty
                return val === "" || validator.helpers.testRegex(val, /^\d{10}$/);
              },
            },
          },
        })
      );
    const [, updateState] = useState({});
    const forceUpdate = useCallback(() => updateState({}), []);
    const [permissionData, setPermissionData] = useState('')
    const [permission, setPermission] = useState('')
    const [helpTicketType,setHelpTicketType] =  useState(helpTicketTypeArr);
    const [gameFilterData, setGameFilterData] = useState([]);
    const [formData,setFormData] = useState({
        email: '',
        fullName: '',
        phoneNumber:'',
        helpTicketType:'',
        helpTicketGameId:'',
        closeModal:false,
        ticketType: ''
    });

    // useEffect(() => {
    //     dispatch(getLeaderboardGameList()).then(res => {
    //         setGameFilterData(res.data.data?.map((item) => { return { value: item?._id, label: item?.gameName} }) || [])
    //     })
    // }, [modalValue]);

    useEffect(() => {
        if (permission) {
            let temp = []
            Object?.keys(permission).map(ele => {
                if (permission[ele]?.editor || permission[ele]?.viewer) {
                    setPermissionData('Testing')
                    temp.push('Testing')
                }
            });
            if (temp?.length > 0) {
                setPermissionData('Testing')
            } else {
                setPermissionData('')
            }
        }
    }, [permission, modalValue]);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (simpleValidator.current.allValid()) {
            let payload = {
                email: formData?.email,
                fullName: formData?.fullName,
                phoneNumber:formData?.phoneNumber,
                permission: {
                    ...permission
                }
            }
          
            Object?.keys(payload).forEach(ele => {
                if (payload[ele] === '' || payload[ele] === null) { delete payload[ele] }
            });
            setLoader(true);
            dispatch(registerAdminUser(payload)).then(res => {
                if (res.data.success) {
                    setLoader(false);
                    // setHelpTicketType(helpTicketTypeArr)
                    redirectApiHandler();
                    setPermission({});
                    dispatch({ type: 'UPDATE_SNACKBAR_STATE', payload: { open:true, message: res?.data?.message, variant:"success", title:"Success" } })
                    handleOpenModal()
                    // setSnackBarData({ open:true, message: res?.data?.message, variant:"success", title:"Success" })
                    // handleOpenModal('CommonPop', { header: "Success", body: res?.data?.message });
                } else {
                    dispatch({ type: 'UPDATE_SNACKBAR_STATE', payload: { open:true, message: res?.data?.message || res?.data?.msg, variant:"error", title:"Error" } })
                    handleOpenModal()
                    // setSnackBarData({ open:true, message: res?.data?.message || res?.data?.msg, variant:"error", title:"Error" })
                    // handleOpenModal('CommonPop', { header: "Error", body: res?.data?.message || res?.data?.msg });
                }
            });

        } else {
            simpleValidator.current.showMessages();
            forceUpdate();
        }
    }

    const cancelHandler = () => {
        setFormData({
            ...formData,
            closeModal:false
        })
        handleOpenModal();
    };

    const changeHandlerFunction = (e) => {
        if (e.target.name === "phoneNumber") {
            let numberRegex = /^\d+$/;
            const { value, name } = e.target;
            setFormData({
              ...formData,
              phoneNumber: numberRegex.test(value)
                ? /^0/.test(value)
                  ? value.replace(/^0/, "")
                  : value
                : "",
              type: name,
            });
        } else if(e.target.name === "email"){
            setFormData({
                ...formData,
                email: e.target.value.trim()
            })
        }else{

            setFormData({
                ...formData,
                [e.target.name]: e.target.value
            })
        }
    }

    useEffect(()=>{
          if(modalValue?.isEdit){
              let temp = {...modalValue?.data?.adminUserPermission};
              delete temp['subAdminUser'];
              setPermission(temp);
              setFormData({
                  ...formData,
                  email: modalValue?.data?.email,
                  fullName: modalValue?.data?.userName,
                  phoneNumber:modalValue?.data?.phoneNumber
              })
          }
    },[modalValue, gameFilterData]);

    const handleEditSubmit = (e) => {
        e.preventDefault();
        if (simpleValidator.current.allValid()) {
            let payload = {
                fullName: formData?.fullName,
                phoneNumber:formData?.phoneNumber,
                permission: {
                    ...permission
                },
                adminUserId: modalValue?.data?._id
            }
            setLoader(true);
            dispatch(updateAdminUserList(payload)).then(res => {
                if (res.data.success) {
                    setLoader(false);
                    redirectApiHandler();
                    dispatch({ type: 'UPDATE_SNACKBAR_STATE', payload: { open:true, message: res?.data?.message, variant:"success", title:"Success" } })
                    handleOpenModal()
                    // setSnackBarData({ open:true, message: res?.data?.message, variant:"success", title:"Success" })
                    // handleOpenModal('CommonPop', { header: "Success", body: res?.data?.message });
                } else {
                    dispatch({ type: 'UPDATE_SNACKBAR_STATE', payload: { open:true, message: res?.data?.message || res?.data?.msg, variant:"error", title:"Error" } })
                    handleOpenModal()
                    // setSnackBarData({ open:true, message: res?.data?.message || res?.data?.msg, variant:"error", title:"Error" })
                    // handleOpenModal('CommonPop', { header: "Error", body: res?.data?.message || res?.data?.msg });
                }
            });
        } else {
            simpleValidator.current.showMessages();
            forceUpdate();
        }

    }


    return (
        <Box sx={style}>
            <div className={'modal_main_popup add_admin_user_popup'}>
                <div className={'modal_popup_title'}>
                    <h2>{`${modalValue?.isEdit ? 'Update Admin' : 'Add Admin'}`}</h2>
                </div>
                <div className={'add_admin_user_popup_content'}>
                    <form method={'POST'} onSubmit={modalValue?.isEdit ? (e)=>handleEditSubmit(e) : (e)=>handleSubmit(e)}>
                        <div className="formData">
                            <label>Enter Full Name <span className={'validation-star'}>*</span> </label>
                            <div className="emailWrap input_length_counter">
                                <input type="text" name='fullName' value={formData?.fullName}  placeholder={'Enter Full Name'} className={'wrap_input_modal'} maxLength={40}   onChange={(e)=>changeHandlerFunction(e)} />
                                <span>{formData?.fullName?.length}/40</span>
                            </div>
                            {simpleValidator.current.message("fullName", formData?.fullName, 'required|min:2')}
                        </div>
                        <div className={'admin_list_flex'}>
                            <div className={modalValue?.isEdit ? "formData left_side_filed readOnly_field" : "formData left_side_filed"}>
                                <label>Enter Email <span className={'validation-star'}>*</span></label>
                                <div className="emailWrap">
                                    <input type="text" name='email' value={formData?.email} placeholder={'Enter Email'} className={modalValue?.isEdit ? 'disabled-email' : ''}  readOnly={modalValue?.isEdit} onChange={(e)=>changeHandlerFunction(e)} />
                                </div>
                                {simpleValidator.current.message("email", formData?.email, "required|email")}
                            </div>
                            <div className="formData right_side_filed">
                                <label>Enter Phone Number</label>
                                <div className="emailWrap">
                                    <input type="tel"  name='phoneNumber' value={formData?.phoneNumber}   maxLength={10} placeholder={'Enter Phone Number'}  onChange={(e)=>changeHandlerFunction(e)} />
                                </div>
                            {simpleValidator.current.message("phoneNumber", formData?.phoneNumber, "phoneNumber|numeric")}
                            </div>
                        </div>
                        <div className={'add_admin_user_role'}>
                            <div className="role_admin">
                                <label>Select Access Role <span className={'validation-star'}>*</span> </label>
                                <CategoryListDropdown setPermission={setPermission} permission={permission} handleOpenModal={handleOpenModal} modalValue={modalValue} modalClose={formData?.closeModal}   />
                                {simpleValidator.current.message("adminUserPermission", permissionData, "required")}
                            </div>
                        </div>
                        <div className={'formData_btn '}>
                            <button className={'btn_default mr_2'} type={'reset'} onClick={() => cancelHandler()}>Cancel</button>
                            <FilledButton type={'submit'} value={modalValue?.isEdit ? 'Update' : 'Save'} className={'btn loader_css'} loading={loader} />
                        </div>
                    </form>
                </div>
            </div>
        </Box>
    )
}
export default AddAdminUserList