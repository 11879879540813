import { Box } from "@mui/material";
import { jsonToFormData, profileImages } from "../../../../../utils";
import user from "../../../../../assets/images/avatar.png";
import icon_plus from "../../../../../assets/images/plus.svg";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import FilledButton from "../../../../../Components/FileButton";
import CommonModal from "../../../../../hoc/CommonModal";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import SimpleReactValidator from "simple-react-validator";
import PopComponent from "../../../../../hoc/PopContent";
import {
  createAvatarData,
  createOfferImageData,
  editAvatarData,
  editOfferImageData,
} from "../../../../../Redux/Avatar/action";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "5px",
};

const AddOfferImages = ({
  modalValue,
  handleOpenModal,
  redirectApiHandler,
}) => {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, updateState] = useState({});
  const forceUpdate = useCallback(() => updateState({}), []);
  const [modalDetails, setModalDetails] = useState({
    modalValue: "",
    modalName: "",
    modalIsOpen: false,
  });
  let Modal = PopComponent[modalDetails.modalName];
  const [formData, setFormData] = useState({
    offerImageName: "",
    offerImage: "",
    isImageUpdated: false,
  });

  const changeHandler = (e) => {
    const { value, name } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleOpenErrorModal = (type, data) => {
    switch (type) {
      case "CommonPop": {
        setModalDetails({
          ...modalDetails,
          modalValue: data,
          modalName: type,
          modalIsOpen: true,
        });
        break;
      }
      default: {
        setModalDetails({ ...modalDetails, modalIsOpen: false });
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (simpleValidator.current.allValid()) {
      let payload = {
        offerImageName: formData?.offerImageName,
        offerImage: formData?.offerImage,
      };
      setLoader(true);
      dispatch(createOfferImageData(jsonToFormData(payload))).then((res) => {
        if (res.data.statusCode === 200 && res.data.success) {
          setLoader(false);
          redirectApiHandler();
          handleOpenModal("CommonPop", {
            header: "Success",
            body: res.data.message,
          });
        } else {
          setLoader(false);
          handleOpenModal("CommonPop", {
            header: "Error",
            body: res.data.message || res?.data?.msg,
          });
        }
      });
    } else {
      simpleValidator.current.showMessages();
      forceUpdate();
    }
  };

  const handleIconChange = (e) => {
    const file = e.target.files[0];
    if (
      file?.type?.includes("image/") &&
      file.type !== "image/gif" &&
      file.type !== "image/svg+xml"
    ) {
      if (file.size <= 5 * 1024 * 1024) {
        // Check if file size is less than or equal to 5 MB
        setFormData({
          ...formData,
          offerImage: file,
          isImageUpdated: true,
        });
      } else {
        handleOpenErrorModal("CommonPop", {
          header: "Error",
          body: "Please select an image file smaller than 5 MB.",
        });
      }
    } else {
      handleOpenErrorModal("CommonPop", {
        header: "Error",
        body: "Please add only .jpg, .jpeg, .png files.",
      });
    }
  };

  const handleEditSubmit = (e) => {
    e.preventDefault();
    if (simpleValidator.current.allValid()) {
      let payload = {
        ...formData,
      };
      if (!payload.isImageUpdated) {
        delete payload.offerImage;
      }
      setLoader(true);
      dispatch(editOfferImageData(jsonToFormData(payload)))
        .then((res) => {
          if (res.data.success) {
            redirectApiHandler();
            setLoader(false);
            handleOpenModal("CommonPop", {
              header: "Success",
              body: res.data.message,
            });
          } else {
            setLoader(false);
            handleOpenModal("CommonPop", {
              header: "Error",
              body: res.data.message || res?.data?.msg,
            });
          }
        })
        .catch((e) => {
          setLoader(false);
        });
    } else {
      simpleValidator.current.showMessages();
      forceUpdate();
    }
  };

  useEffect(() => {
    if (modalValue?.isEdit) {
      setFormData({
        ...formData,
        offerImageName: modalValue?.row?.offerImageName,
        offerImage: modalValue?.row?.offerImage,
        offerImageId: modalValue?.row?._id,
      });
    }
  }, [modalValue?.isEdit]);

  return (
    <Box sx={style}>
      <div
        className={
          "add_admin_user_popup modal_main_popup add_avatar_section lobby_section_details"
        }
      >
        <div className={"modal_popup_title"}>
          <h2>{modalValue?.isEdit ? "Update Offer Image" : "Add Offer Image"}</h2>
        </div>
        <div className={"add_admin_user_popup_content mt_15"}>
          <form
            onSubmit={
              modalValue?.isEdit
                ? (e) => handleEditSubmit(e)
                : (e) => handleSubmit(e)
            }
          >
            <div className={"level_popup_form_field "}>
              <div className="form_group profile new_game_section profile-image-dropdown">
                <div className="user_profile">
                  <div className="user_profile_pic">
                    {profileImages(formData?.offerImage, user)}
                    {!formData?.offerImage && (
                      <span className="addnew">
                        <img src={icon_plus} alt="" />
                        <input
                          type="file"
                          name="labelIcon"
                          id=""
                          onChange={(e) => handleIconChange(e)}
                        />
                      </span>
                    )}
                  </div>
                  <label htmlFor="" className="profile_label">
                    Offer Image <span className={"validation-star"}>*</span>
                  </label>
                </div>
                {simpleValidator.current.message(
                  "offerImage",
                  formData?.offerImage,
                  "required"
                )}
                {formData?.offerImage && (
                  <div
                    className={"close-icon"}
                    onClick={() =>
                      setFormData({ ...formData, offerImage: "" })
                    }
                  >
                    <CloseSharpIcon />
                  </div>
                )}
              </div>
              <div className={"level_popup_form_field_left"}>
                <div className={"user_kyc_section"}>
                  <div className={"user_kyc_section_filed"}>
                    <label>
                      Name <span className={"validation-star"}>*</span>
                    </label>
                    <div
                      className={
                        "user_kyc_section_input_filed lobby-type-description"
                      }
                    >
                      <input
                        type={"text"}
                        placeholder={"Enter Name"}
                        maxLength={40}
                        value={formData?.offerImageName}
                        name={"offerImageName"}
                        onChange={(e) => changeHandler(e)}
                      />
                      <span>{formData?.offerImageName?.length}/40</span>
                    </div>
                    {simpleValidator.current.message(
                      "Name",
                      formData?.offerImageName,
                      "required"
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className={"formData_btn"}>
              <button
                className={"btn_default"}
                type={"reset"}
                onClick={() => handleOpenModal()}
              >
                Cancel
              </button>
              <FilledButton
                type={"submit"}
                value={modalValue?.isEdit ? "Update" : "Save"}
                className={"btn loader_css"}
                loading={loader}
              />
            </div>
          </form>
        </div>
      </div>
      <CommonModal
        className={"Approved-reject-section"}
        modalIsOpen={modalDetails.modalIsOpen}
        handleOpenModal={handleOpenErrorModal}
      >
        <Modal
          modalValue={modalDetails.modalValue}
          handleOpenModal={handleOpenErrorModal}
          modalIsOpen={modalDetails.modalIsOpen}
        />
      </CommonModal>
    </Box>
  );
};

export default AddOfferImages;
