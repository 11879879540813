import React, {useEffect, useState} from "react";
import Box from "@material-ui/core/Box";
import {CircularProgress} from "@mui/material";
import Clip from "../AddTicketsVideo/VideoPlayer";


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    height:400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: "5px",
};

const ViewVideoImage = ({modalValue}) => {
    const [loader,setLoader] = useState(false)

    useEffect(()=>{
        setLoader(true)
        setTimeout(()=>{
            setLoader(false)
        },500)
    },[]);

    return(
        <Box sx={style}>
            <div className={'ticket_video_image_section'}>
                <h2>View Video</h2>
                {
                    loader ?
                    <div className={'loader_ticket_video'}>
                        <div className={'btn-loader loader_css'}>
                            <CircularProgress />
                        </div>
                    </div>
                        :
                        <div className={'ticket_show'}>
                            {
                                modalValue?.isImage ?
                                    <img src={modalValue?.ticketVideoImage} alt={'images'}/>
                                    :
                                    <Clip url={modalValue?.ticketVideo}/>
                            }

                        </div>
                }

            </div>
        </Box>
    )
}
export default ViewVideoImage