const LogoutIcon = () =>{
    return(
        <div className={'Game_Icon'}>
          <svg viewBox="0 0 20 16" version="1.1"  fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false">
              <g id="sign-out-alt-solid">
                  <path d="M19.7222 8.29449L13.0556 14.9612C12.4603 15.5564 11.4286 15.1397 11.4286 14.2866L11.4286 10.477L6.03175 10.477C5.50397 10.477 5.07937 10.0524 5.07937 9.52465L5.07937 5.71513C5.07937 5.18735 5.50397 4.76275 6.03175 4.76275L11.4286 4.76275L11.4286 0.953225C11.4286 0.104019 12.4563 -0.316616 13.0556 0.278622L19.7222 6.94529C20.0913 7.3183 20.0913 7.92148 19.7222 8.29449ZM7.61905 14.7627L7.61905 13.1754C7.61905 12.9135 7.40476 12.6993 7.14286 12.6993L3.80952 12.6993C3.10714 12.6993 2.53968 12.1318 2.53968 11.4294L2.53968 3.81037C2.53968 3.10799 3.10714 2.54053 3.80952 2.54053L7.14286 2.54053C7.40476 2.54053 7.61905 2.32624 7.61905 2.06434L7.61905 0.477034C7.61905 0.21513 7.40476 0.000843994 7.14286 0.000843994L3.80952 0.000843994C1.70635 0.000843994 0 1.70719 0 3.81037L0 11.4294C0 13.5326 1.70635 15.2389 3.80952 15.2389L7.14286 15.2389C7.40476 15.2389 7.61905 15.0247 7.61905 14.7627Z" id="Shape" fill="currentColor" stroke="none"></path>
              </g>
          </svg>
      </div>
    )
}
export default LogoutIcon