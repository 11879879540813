import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ErrorBoundary } from "react-error-boundary";
import Loader from "./hoc/LoaderMain";
import ErrorFallback from "./ErrorFallback";
import { BrowserRouter } from "react-router-dom";
import store from "./Redux/store";
import { Provider } from "react-redux";

ReactDOM.render(
  <React.StrictMode>
  <Provider store={store}>
  <BrowserRouter >
      <ErrorBoundary FallbackComponent={ErrorFallback}>
          <Suspense fallback={<Loader className={'content-loader'}/>}>
             <App />
          </Suspense>
      </ErrorBoundary>
  </BrowserRouter>
  </Provider>
</React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
