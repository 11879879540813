import { Box } from "@mui/material";
import FilledButton from "../../../../../Components/FileButton";
import React, { useCallback, useEffect, useRef, useState } from "react";
import SimpleReactValidator from "simple-react-validator";
import { useDispatch } from "react-redux";
import {
  updateMinimumWithdrawCash,
  updateSignUpCash,
} from "../../../../../Redux/settings/action";
import { updateSignUpBonus } from "Redux/settings/action";
import { addDailyBonus, addDailyBonusList, updateDailyBonus } from "Redux/Bonus/action";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 550,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "5px",
};

const AddDailyBonusPopUp = ({ modalValue,
  handleOpenModal,
  redirectApiHandler,}) => {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [, updateState] = useState({});
  const forceUpdate = useCallback(() => updateState({}), []);
  const simpleValidator = useRef(new SimpleReactValidator());
  const [formData, setFormData] = useState({
    bonus: "",
  });
  const displayDay = modalValue?.row?.day

  const handleSubmit = (e) => {
    e.preventDefault();
    if (simpleValidator.current.allValid()) {
      //  const payload = {
      //    gameId: "65ca3c584a0726d292f58e67",
      //    dailyBonus: {
      //        [displayDay] : formData?.bonus
      //    }
      //  }
      setLoader(true);
      if (modalValue?.row?.bonus) {
        const payload = {
          day: modalValue?.row?.day,
          bonus: formData.bonus,
          dailyBonusId: modalValue?.row?._id,
        };
        dispatch(updateDailyBonus(payload))
          .then((res) => {
            if (res.data.success) {
              setLoader(false);
              redirectApiHandler();
              dispatch({ type: 'UPDATE_SNACKBAR_STATE', payload: { open:true, message: res?.data?.message, variant:"success", title:"Success" } })
              handleOpenModal()
              // handleOpenModal("CommonPop", {
              //   header: "Success",
              //   body: res.data.message,
              // });
            } else {
              setLoader(false);
              dispatch({ type: 'UPDATE_SNACKBAR_STATE', payload: { open:true, message: res?.data?.message || res?.data?.msg, variant:"error", title:"Error" } })
              handleOpenModal()
              // handleOpenModal("CommonPop", {
              //   header: "Error",
              //   body: res.data.message || res.data.msg,
              // });
            }
          })
          .catch((err) => {
            setLoader(false);
          });
      } else {
        const payload = {
          day: modalValue?.row?.day,
          bonus: formData.bonus,
        };
        dispatch(addDailyBonus(payload))
          .then((res) => {
            if (res.data.success) {
              setLoader(false);
              redirectApiHandler();
              handleOpenModal("CommonPop", {
                header: "Success",
                body: res.data.message,
              });
            } else {
              setLoader(false);
              handleOpenModal("CommonPop", {
                header: "Error",
                body: res.data.message || res.data.msg,
              });
            }
          })
          .catch((err) => {
            setLoader(false);
          });
      }
      // dispatch(addDailyBonusList(payload)).then(
      //   (res) => {
      //     if (res.data.success) {
      //       setLoader(false);
      //       redirectApiHandler();
      //       handleOpenModal("CommonPop", {
      //         header: "Success",
      //         body: res.data.message,
      //       });
      //     } else {
      //       handleOpenModal("CommonPop", {
      //         header: "Error",
      //         body: res.data.message || res.data.msg,
      //       });
      //     }
      //   }
      // );
    } else {
      simpleValidator.current.showMessages();
      forceUpdate();
    }
  };

  useEffect(() => {
    setFormData({
      ...formData,
      bonus: modalValue?.row?.bonus,
    });
  }, [modalValue]);
  return (
    <Box sx={style}>
    <div className={"modal_main_popup add_admin_user_popup update_user_cash"}>
      <div className={"modal_popup_title"}>
        <h2 className={"config_text"}> Update Daily Bonus</h2>
      </div>
      <div className={"add_admin_user_popup_content_pop"}>
        <form method={"POST"} onSubmit={(e) => handleSubmit(e)}>
          <div className="formData">
            <label className={"config_text"}>
             DAY {displayDay} <span className={"validation-star"}>*</span>
            </label>
            <div className="emailWrap">
              <div className={"input_length_counter"}>
              <input
                type="text"
                maxLength={10}
                name="cash"
                value={formData?.bonus}
                onChange={(e) => {
                  let numberRegex = /^\d+$/;
                  let inputValue = e.target.value;
                  // If the input value is just "0", set the amount to an empty string
                  if (inputValue === "0") {
                    setFormData({
                      ...formData,
                      bonus: "",
                    });
                    return;
                  }
                  // Remove leading zeros if the user enters any
                  if (inputValue.length > 1 && inputValue[0] === "0") {
                    inputValue = inputValue.slice(1);
                  }
                  // Validate the input using numberRegex and update the state accordingly
                  setFormData({
                    ...formData,
                    bonus: numberRegex.test(inputValue) ? inputValue : "",
                  });
                }}
              />
                <span className={"game_edit_info_span"}>
                  {formData?.bonus?.toString()?.length}/10
                </span>
              </div>
            </div>
            {simpleValidator.current.message(
              modalValue?.name === "signup cash"
                ? "signup cash"
                : modalValue?.name === "signup bonus"
                ? "signup bonus"
                : "signup cash",
              formData?.bonus?.toString(),
              "required|numeric"
            )}
          </div>
          <div className={"formData_btn"}>
            <button
              className={"btn_default mr_2"}
              onClick={() => handleOpenModal()}
            >
              Cancel
            </button>
            <FilledButton
              type={"submit"}
              value={"Submit"}
              className={"btn"}
              loading={loader}
            />
          </div>
        </form>
      </div>
    </div>
  </Box>
  )
}

export default AddDailyBonusPopUp