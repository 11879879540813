import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { MultiSelect } from "react-multi-select-component";
import { Optional } from "../../../../../../../images/OptionalIcon";
import FilledButton from "../../../../../../../Components/FileButton";
import { useDispatch, useSelector } from "react-redux";
import { getAllCountriesRestrictGeo } from "../../../../../../../Redux/settings/action";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DatePicker from "@mui/lab/DatePicker";
import TextField from "@mui/material/TextField";
import user from "../../../../../../../assets/images/avatar.png";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: "5px",
};

const CreateRecurringTournament = () => {
    const dispatch = useDispatch();
    const [selected, setSelected] = useState([]);
    const geoData = useSelector(state => state?.settingReducer?.restrictedGeo);
    const [loader, setLoader] = useState(false);
    const [startDate, setStartDate] = useState(new Date());

    const handleSubmit = (e) => {

    }
    useEffect(() => {
        dispatch(getAllCountriesRestrictGeo({}));
    }, []);

    const getCountryData = () => {
        return geoData?.country?.map((item) => { return { value: item, label: item } }) || [];
    };

    return (
        <Box sx={style}>
            <div className={'create_headToHead_modal create_recurring_tournament add_admin_user_popup'}>
                <div className={'add_admin_user_popup_title'}>
                    <h2>{`Create Event Tournament`}</h2>
                </div>
                <div className={'add_admin_user_popup_content'}>
                    <form method={'POST'} onSubmit={(e) => handleSubmit(e)}>
                        <div className="formData">
                            <label>Tournament Name</label>
                            <div className="emailWrap">
                                <input type="text" className={'wrap_input_modal'} name='tournamentName' placeholder={'Enter tournament name'} />
                                <span>0/28</span>
                            </div>
                        </div>
                        <div className="formData">
                            <label>Description (optional) </label>
                            <div className="text_Wrap emailWrap">
                                <input type={'text'} className={'wrap_input_modal'} name='description' placeholder={'Enter tournament description'} />
                                <span>0/50</span>
                            </div>
                        </div>
                        <div className={'add_admin_user_role formData'}>
                            <label>Tournament Type</label>
                            <select className={'role_select_dropdown'} name={'tournamentType'} >
                                <option value={''}>Select TournamentType</option>
                                <option value={''}>Asynchronous</option>
                                <option value={''}>Synchronous</option>
                            </select>
                        </div>
                        <div className="formData">
                            <div className="emailWrap profile_icon">
                                <div className='user_profile'>
                                    <label htmlFor='' className='profile_label'>Cover Image (optional) <Optional /></label>
                                    <div className={'header_section_slider'}>
                                        <div className='user_profile_pic'>
                                            <img src={user} alt='' />
                                            <span className='add_new'>
                                                <input type='file' name='community_logo' id='' /> </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='country-user-details '>
                            <label>Country Availability</label>
                            <MultiSelect
                                value={selected}
                                options={getCountryData()}
                                selected={selected}
                                onChange={setSelected}
                                labelledBy={"Select"}
                            />
                        </div>

                        <div className={'formData tournament_setting_content prize_amount'}>
                            <h3>Prize Amount</h3>
                            <div className={'tournament_setting_amount'}>
                                <div className="formData form_amount_tab01">
                                    <label>Tournament Entry Fee ($) <Optional /></label>
                                    <div className="emailWrap tournament_entry_fee">
                                        <input type="text" name='tournamentName' placeholder={'Number Of Users'} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={'formData tournament_setting_content prize_amount'}>
                            <h3>Entrant Settings  </h3>
                            <div className={'tournament_setting_amount'}>
                                <div className="formData form_amount_tab01">
                                    <label>Minimum Entries</label>
                                    <div className="emailWrap">
                                        <input type="text" name='tournamentName' placeholder={'Number Of Users'} />
                                    </div>
                                </div>
                                <div className="formData form_amount_tab02 tab02_inner_class">
                                    <label>Maximum Entries <Optional /></label>
                                    <div className="emailWrap">
                                        <input type="text" name='tournamentName' placeholder={'Number Of Users'} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={'formData checkbox_modal'}>
                            <label>Allow Multiple Entries? <Optional /> </label>
                            <div className={'d-flex-wrap'}>
                                <div className={'form_data_field'}>
                                    <input type="radio" name='entries' className={'checkbox_field_tournament'} />
                                    <label>Yes</label>
                                </div>
                                <div className={'form_data_field tab_no'}>
                                    <input type="radio" name={'entries'} className={'checkbox_field_tournament'} />
                                    <label>No</label>
                                </div>
                            </div>
                        </div>
                        <div className={'formData tournament_setting_content prize_amount'}>
                            <h3 className={'tournament_title_field'}>Tournament Scheduling (  <span> Timezone set to French Guiana Time</span>)</h3>
                            <div className={'time_zone_tournament'}>
                                <div className="formData">
                                    <label>Start date </label>
                                    <div className={'date-show'}>
                                        <LocalizationProvider dateAdapter={AdapterDateFns} >
                                            <DatePicker
                                                name='start-date'
                                                value={startDate}
                                                onChange={(newValue) => { setStartDate(newValue) }}
                                                maxDate={new Date()}
                                                renderInput={(params) => {
                                                    return <TextField {...params} />
                                                }}
                                                inputProps={{ readOnly: true }}
                                                inputFormat="MMM dd, yyyy"
                                            />
                                        </LocalizationProvider>
                                    </div>
                                </div>
                                <div className="formData mangingRight_tab">
                                    <label>Duration</label>
                                    <div className={'d-flex_wrap'}>
                                        <div className="emailWrap tournamentTab01">
                                            <input type="text" name='tournamentName' placeholder={'Duration'} />
                                        </div>
                                        <div className="emailWrap tournamentTab02">
                                            <input type="text" name='tournamentName' placeholder={'Enter tournament name'} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="formData ">
                            <label>Repeat Every</label>
                            <div className={'d-flex_wrap repeatEvery'}>
                                <div className="emailWrap tournamentTab01">
                                    <input type="text" name='tournamentName' placeholder={'Duration'} />
                                </div>
                                <div className="emailWrap tournamentTab02">
                                    <input type="text" name='tournamentName' placeholder={'Enter tournament name'} />
                                </div>
                            </div>
                        </div>

                        <div className="formData time_zone_tournament">
                            <div>
                                <label>End date </label>
                                <div className={'date-show'}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns} >
                                        <DatePicker
                                            name='start-date'
                                            value={startDate}
                                            onChange={(newValue) => { setStartDate(newValue) }}
                                            maxDate={new Date()}
                                            renderInput={(params) => {
                                                return <TextField {...params} />
                                            }}
                                            inputProps={{ readOnly: true }}
                                            inputFormat="MMM dd, yyyy"
                                        />
                                    </LocalizationProvider>
                                </div>
                            </div>
                        </div>
                        <div className="formData checkbox_input">
                            <div className="emailWrap">
                                <input type="checkbox" />
                                <label>Never</label>
                            </div>
                        </div>

                        <div className={'formData tournament_setting_content prize_amount'}>
                            <h3 className={'tournament_title_field'}>  <span>Prize Pool:</span> ($0)(Estimated) <Optional /></h3>
                            <div className={'time_zone_tournament'}>
                                <div className="formData prize-tab0">
                                    <label>No. of Users</label>
                                    <div className="text_Wrap emailWrap">
                                        <input type={'text'} name='description' placeholder={'Enter tournament description'} />
                                    </div>
                                </div>
                                <div className="formData prize-tab01">
                                    <label>Rank</label>
                                    <div className="text_Wrap emailWrap">
                                        <input type={'text'} name='description' placeholder={'Enter tournament description'} />
                                    </div>
                                </div>
                                <div className="formData prize-tab02">
                                    <label>Prize Amount (%)</label>
                                    <div className="text_Wrap emailWrap">
                                        <input type={'text'} name='description' placeholder={'Enter prize Amount'} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={'formData'}>
                            <div className={'add_row'}>
                                <div>
                                    <button>+ Add Row</button>
                                </div>
                                <div>
                                    0/100%
                                </div>
                            </div>
                        </div>
                        <div className={'formData_btn'}>
                            <button className={'cancel_btn'} type={'reset'} >Cancel</button>
                            <FilledButton type={'submit'} value={'Submit'} className={'submit_btn loader_css'} loading={loader} />
                        </div>
                    </form>
                </div>
            </div>
        </Box>
    )
}
export default CreateRecurringTournament