import React, {useEffect, useState} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import List from '@mui/material/List';
import { Avatars } from 'images/Avatars';
import GameIcon from "../../images/Game";
import SDKIcon from "../../images/SDKIcon";
import UserIcon from "../../images/UserIcon";
import Collapse from '@mui/material/Collapse';
import Coupon from "../../images/Coupon";
import SettingIcon from "../../images/settingIcon";
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import AnalyticsIcon from "../../images/AnalyticsIcon";
import Logo from "../../assets/images/LudoLogo";
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ListItemButton from '@mui/material/ListItemButton';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import RevenueIcon from "../../images/RevenueIcon";
import {agentDetails, hideActionFunc, userDetails} from "../../utils";
import {getFlagConfig} from "../../Redux/settings/action";
import {useDispatch, useSelector} from "react-redux";
import {getPermissionsKey} from "../../Redux/AdminUser/action";
import HelpAndDesk from "../../images/HelpAndDesk"
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import ViewModuleIcon from '@mui/icons-material/ViewModule';

function LeftContent(props) {
    const dispatch =  useDispatch()
    const settingFlag = useSelector(state => state?.settingReducer?.flagList);
    const navigate = useNavigate();
    const [, updateState] = React.useState({});
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [permotions,setPermotions] = useState({})
    const [menusList, setMenuList] = useState([
        {
            icon:  <ViewModuleIcon />,
            label: "Dashboard",
            src: "dashboard-data",
            value: 'dashboard',
            hasMoreMenu: false,
        },
        // Admin Users menu
        {
            icon: <UserIcon />,
            label: "Admin Users",
            src: "admin-users",
            value: 'admin',
            hasMoreMenu: false,
        },
        // Users menu with expandable submenus
        {
            icon: <UserIcon />,
            label: "Users",
            hasMoreMenu: true,
            isExpanded: false,
            id: 'userId',
            value: 'user',
            expandArray: [
                {
                    icon: '',
                    label: "All Users",
                    src: "Users",
                },
                {
                    icon: '',
                    label: "Inactive Users",
                    src: "inactive-users",
                },
                {
                    icon: '',
                    label: "Users Blocked",
                    src: "block-user",
                },
                // {
                //     icon: '',
                //     label: "Users Reported",
                //     src: "user-reported",
                // },
            ]
        },
        {
            icon:  <LeaderboardIcon />,
            label: "Lobby",
            src: "ludo-lobby",
            value: 'lobby',
            hasMoreMenu: false,
        },
        // Analytics menu with expandable submenus
        {
            icon: <AnalyticsIcon />,
            label: "Analytics   ",
            hasMoreMenu: true,
            isExpanded: false,
            id: 'analytics',
            value: 'analytics',
            expandArray: [
                {
                    icon: '',
                    label: "Analytics Report",
                    src: "analytics-report",
                },
                {
                    icon: '',
                    label: "Analytics Overview Report",
                    src: "analytics-overview-report",
                },
                {
                    icon: '',
                    label: "Application Install",
                    src: "analytics-application-install",
                },
                {
                    icon: '',
                    label: "Daily Report",
                    src: "daily-report",
                },
            ]
        },
        // Bonus menu with expandable submenus
        {
            icon: <Coupon />,
            label: "Bonus",
            hasMoreMenu: true,
            isExpanded: false,
            id: 'bonusId',
            value: 'bonus',
            expandArray: [
                {
                    icon: '',
                    label: "Daily Bonus",
                    src: "daily-spin-bonus",
                },
                // {
                //     icon: '',
                //     label: "Refer & Earn",
                //     src: "refer-and-earn",
                // },
            ]
        },
        // Revenue menu with expandable submenus
        // {
        //     icon: <RevenueIcon />,
        //     label: "Revenue",
        //     hasMoreMenu: true,
        //     isExpanded: false,
        //     id: 'revenue',
        //     value: 'revenue',
        //     expandArray: [
        //         {
        //             icon: '',
        //             label: "Package Store Revenue",
        //             src: "revenue/package-store-revenue",
        //         },
        //         // {
        //         //     icon: '',
        //         //     label: "Game Revenue",
        //         //     src: "revenue/game-revenue",
        //         // },
        //         // {
        //         //     icon: '',
        //         //     label: "Platform Report",
        //         //     src: "platform-report",
        //         // },
        //     ]
        // },
        {
            icon: <UserIcon />,
            label: "Avatars",
            src: "avatars",
            value: "avatars"
        },
        // Master menu with expandable submenus
        {
            icon: <LeaderboardIcon />,
            label: "Store",
            hasMoreMenu: true,
            isExpanded: false,
            id: 'master',
            value: 'store',
            expandArray: [
                // {
                //     icon: '',
                //     label: "Avatars",
                //     src: "avatars",
                // },
                // {
                //     icon: '',
                //     label: 'Documentation',
                //     src: 'documentation'
                // },
                // {
                //     icon: '',
                //     label: 'Online Players',
                //     src: 'online-players'
                // },
                {
                    icon: '',
                    label:  "Offer",
                    src: "OfferTab",
                },
                {
                    icon: '',
                    label: 'In App',
                    src: 'in-app'
                  },
            ]
        },
        // Marketing menu with expandable submenus
        // {
        //     icon: <TrendingUpIcon />,
        //     label: "Marketing",
        //     hasMoreMenu: true,
        //     isExpanded: false,
        //     id: 'marketing',
        //     value: 'marketing',
        //     expandArray: [
        //         {
        //             icon: '',
        //             label: "Promotion Ads List",
        //             src: "ads-list",
        //         },
        //         // {
        //         //     icon: '',
        //         //     label:  "Offer",
        //         //     src: "OfferTab",
        //         // },
        //         {
        //             icon: '',
        //             label: "Notification",
        //             src: "notification",
        //         },
        //     ]
        // },
        // Design menu with expandable submenus
        // {
        //     icon:  <ViewModuleIcon />,
        //     label: "Design",
        //     hasMoreMenu: true,
        //     isExpanded: false,
        //     id: 'design',
        //     value: 'design',
        //     expandArray: [
        //         {
        //             icon: '',
        //             label: "Home Screen",
        //             src: "Homescreen",
        //         },
        //         {
        //             icon: '',
        //             label: "Refer and Earn",
        //             src: "referAndEarn",
        //         },
            

        //     ]
        // },
       
        // Settings menu with expandable submenus
        // {
        //     icon: <SettingIcon />,
        //     label: "Settings",
        //     hasMoreMenu: true,
        //     isExpanded: false,
        //     id: 'settings',
        //     value: 'setting',
        //     expandArray: [
        //         // {
        //         //     icon: '',
        //         //     label: "Flag Config",
        //         //     src: "setting/flag-config",
        //         // },
        //         // {
        //         //     icon: '',
        //         //     label: "Game Config",
        //         //     src: "GameConfig",
        //         // },
        //         {
        //             icon: '',
        //             label: "Maintenance",
        //             src: "setting/maintenance",
        //         },
        //         {
        //             icon: '',
        //             label: "Restrict Geo",
        //             src: "setting/restrict-geo",
        //         },
        //         {
        //             icon: '',
        //             label: "Error Code",
        //             src: "setting/error-code",
        //         },
        //     ]
        // },
        // {
        //     icon: <HelpAndDesk />,
        //     label: "Game Config",
        //     src: "GameConfig",
        //     hasMoreMenu: false,
        //     value: 'GameConfig',
        //     routeKey: 'GameConfig',
        // },
        // MGP Release menu
        // {
        //     icon: <SDKIcon />,
        //     label: "Release",
        //     src: "release",
        //     value: 'mgpRelease',
        //     hasMoreMenu: false,
        // },
        {
            icon: <SDKIcon />,
            label: "Bots",
            src: "bot",
            value: 'bots',
            hasMoreMenu: false,
        },
        // Help & Support menu
        // {
        //     icon: <HelpAndDesk />,
        //     label: "Help & Support",
        //     src: "help-and-support",
        //     hasMoreMenu: false,
        //     value: 'helpAndSupport',
        //     routeKey: 'help-and-support',
        // },
        // Company Logo menu
        // {
        //     icon: <SDKIcon />,
        //     label: "Company Logo",
        //     src: "company-logo",
        //     hasMoreMenu: false,
        //     value: 'companyLogo',
        //     routeKey: 'company-logo',
        // },
    ]);
    const {role:userRole, email} = userDetails();


    useEffect(()=>{
        dispatch(getFlagConfig({}));
    },[menusList])

    // useEffect(()=>{
    //     if(JSON.parse(localStorage.getItem('userdata'))?.role === 'AdminUser'){
    //         let temp = [...menusList]
    //         setMenuList([ {
    //             icon: <UserIcon />,
    //             label: "Sub Admin Users",
    //             src: "sub-adminUser",
    //             value: 'subAdminUser',
    //             hasMoreMenu: false,
    //         }, ...temp])
    //     }
    // },[])

    const handleClicked = (e) => {
        navigate(`/${e}`)
    };

    const ToggleMenu = (Id) => {
        // Find the menu item with the specified Id and toggle its isExpanded property
        const menu = menusList.find(item => item.id === Id);
        if (menu) {
            menu.isExpanded = !menu.isExpanded;
            forceUpdate(); // Update the component to reflect the changes
        }
    };

    const handleSidebar = (menu) => {
        // Check if neither isGameLeaderboardBonus nor isMobileNumberUpdateRequest is true
        if (!settingFlag?.isGameLeaderboardBonus && !settingFlag?.isMobileNumberUpdateRequest) {
            // Filter expandArray items that don't include 'Users Mobile Number Request' or 'Leaderboard'
            return menu?.expandArray
                ?.filter(
                    (item) =>
                        !item?.label?.includes('Users Mobile Number Request') &&
                        !item?.label?.includes('Leaderboard')
                )
                ?.map((list, i) => {
                    // Render the Collapse component with specific conditions
                    return (
                        list && (
                            <Collapse
                                in={menu?.isExpanded}
                                timeout="auto"
                                unmountOnExit
                                key={i}
                                className={
                                    (window.location.pathname.includes('/users-tab') &&
                                        list?.label === 'All Users') ||
                                    (window.location.pathname.includes('/game-tab') &&
                                        list?.label === 'All Games')
                                        ? 'inner_list_details activeClass'
                                        : 'inner_list_details'
                                }
                            >
                                <List component="div" disablePadding onClick={() => handleClicked(list?.src)}>
                                    <ListItemButton className="list_item" selected={list?.src === window.location.pathname.replace('/', '')}>
                                        {list?.icon && (
                                            <ListItemIcon>
                                                {list?.icon}
                                            </ListItemIcon>
                                        )}
                                        <ListItemText primary={list?.label} className="menu_label fontFamily" />
                                    </ListItemButton>
                                </List>
                            </Collapse>
                        )
                    );
                });
        }

        // Check if isGameLeaderboardBonus is false
        if (!settingFlag?.isGameLeaderboardBonus) {
            // Filter expandArray items that are not labeled as 'Leaderboard'
            return menu?.expandArray
                ?.filter((item) => item?.label !== 'Leaderboard')
                ?.map((list, i) => {
                    // Render the Collapse component with specific conditions
                    return (
                        list && (
                            <Collapse
                                in={menu?.isExpanded}
                                timeout="auto"
                                unmountOnExit
                                key={i}
                                className={
                                    (window.location.pathname.includes('/users-tab') &&
                                        list?.label === 'All Users') ||
                                    (window.location.pathname.includes('/game-tab') &&
                                        list?.label === 'All Games')
                                        ? 'inner_list_details activeClass'
                                        : 'inner_list_details'
                                }
                            >
                                <List component="div" disablePadding onClick={() => handleClicked(list?.src)}>
                                    <ListItemButton className="list_item" selected={list?.src === window.location.pathname.replace('/', '')}>
                                        {list?.icon && (
                                            <ListItemIcon>
                                                {list?.icon}
                                            </ListItemIcon>
                                        )}
                                        <ListItemText primary={list?.label} className="menu_label fontFamily" />
                                    </ListItemButton>
                                </List>
                            </Collapse>
                        )
                    );
                });
        }

        // Check if isMobileNumberUpdateRequest is false
        if (!settingFlag?.isMobileNumberUpdateRequest) {
            // Filter expandArray items that are not labeled as 'Users Mobile Number Request'
            return menu?.expandArray
                ?.filter((item) => item?.label !== 'Users Mobile Number Request')
                ?.map((list, i) => {
                    // Render the Collapse component with specific conditions
                    return (
                        list && (
                            <Collapse
                                in={menu?.isExpanded}
                                timeout="auto"
                                unmountOnExit
                                key={i}
                                className={
                                    (window.location.pathname.includes('/users-tab') &&
                                        list?.label === 'All Users') ||
                                    (window.location.pathname.includes('/game-tab') &&
                                        list?.label === 'All Games')
                                        ? 'inner_list_details activeClass'
                                        : 'inner_list_details'
                                }
                            >
                                <List component="div" disablePadding onClick={() => handleClicked(list?.src)}>
                                    <ListItemButton className="list_item" selected={list?.src === window.location.pathname.replace('/', '')}>
                                        {list?.icon && (
                                            <ListItemIcon>
                                                {list?.icon}
                                            </ListItemIcon>
                                        )}
                                        <ListItemText primary={list?.label} className="menu_label fontFamily" />
                                    </ListItemButton>
                                </List>
                            </Collapse>
                        )
                    );
                });
        }

        // Render all expandArray items
        if (settingFlag?.isGameLeaderboardBonus && settingFlag?.isMobileNumberUpdateRequest) {
            return menu?.expandArray?.map((list, i) => {
                // Render the Collapse component with specific conditions
                return (
                    list && (
                        <Collapse
                            in={menu?.isExpanded}
                            timeout="auto"
                            unmountOnExit
                            key={i}
                            className={
                                (window.location.pathname.includes('/users-tab') &&
                                    list?.label === 'All Users') ||
                                (window.location.pathname.includes('/game-tab') &&
                                    list?.label === 'All Games')
                                    ? 'inner_list_details activeClass'
                                    : 'inner_list_details'
                            }
                        >
                            <List component="div" disablePadding onClick={() => handleClicked(list?.src)}>
                                <ListItemButton className="list_item" selected={list?.src === window.location.pathname.replace('/', '')}>
                                    {list?.icon && (
                                        <ListItemIcon>
                                            {list?.icon}
                                        </ListItemIcon>
                                    )}
                                    <ListItemText primary={list?.label} className="menu_label fontFamily" />
                                </ListItemButton>
                            </List>
                        </Collapse>
                    )
                );
            });
        }

    };

    useEffect(()=>{
        dispatch(getPermissionsKey()).then(res=>{
            console.log({res})
            setPermotions(res.data.data.agentData)
        })
    },[])

    return (
        <>
            <div className='gp_left-bar'>
                {/* Close button */}
                <p className={'custom_close_btn sidebar_closeBtn'} onClick={props.MenuToggle}>
                    {/* Close button SVG */}
                    <svg viewBox="0 0 24 24" x="1008" y="432" fit="" height="28" width="25" preserveAspectRatio="xMidYMid meet" focusable="false">
                        <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z" fill="#64748b" />
                    </svg>
                </p>
                {/* Logo */}
                <div className={'side_logo_box'}>
                    <Link className='side_logo' to="">
                        <img src={Logo} alt={"Logo"} className={"Logo"} />
                    </Link>
                </div>
                {/* Menu List */}
                <List sx={{ width: '100%', maxWidth: 360, padding: '0' }} component="nav" aria-labelledby="nested-list-subheader" className={"tab_sidebar_details"}>
                    {menusList?.map((menu, i) => {
                        // Check permissions and conditions
                        //JSON.parse(localStorage.getItem('agentData')) ||
                        let agentDataDetails =  agentDetails();
                        let payload = {
                            permission: { ...permotions.permission }
                        };
                        let AgentCondition = agentDataDetails !== 'null' && (payload && menu.value === (Object?.keys(payload?.permission || {})[Object?.keys(payload?.permission || {})?.indexOf(menu.value)]));
                        let agentValueViewer = (Object?.keys(payload?.permission || {})?.length > 0 && payload?.permission[(Object?.keys(payload?.permission || {})[Object?.keys(payload?.permission || {})?.indexOf(menu.value)])]?.viewer);
                        let agentValueEditor = (Object?.keys(payload?.permission || {})?.length > 0 && payload?.permission[(Object?.keys(payload?.permission || {})[Object?.keys(payload?.permission || {})?.indexOf(menu.value)])]?.editor);
                        let agentValue = (agentValueViewer ||  agentValueEditor);
                        //JSON.parse(localStorage.getItem('userdata'))?.role ||
                        return (
                            <React.Fragment key={i}>
                                {/* Check if user has permission to view the menu */}
                                {(((userRole) === 'Admin') || (AgentCondition && agentValue)) && (
                                    <>
                                        {/* Menu Item */}
                                        <ListItemButton sx={{ padding: "5px 15px" }} selected={menu?.src === window.location.pathname.replace('/', '')} className='list_item fontFamily' onClick={() => menu.hasMoreMenu ? ToggleMenu(menu?.id) : handleClicked(menu?.src)}>
                                            <ListItemIcon style={{ minWidth: "20px" }} className={'icon-left-side fontFamily'}>
                                                {menu?.icon}
                                            </ListItemIcon>
                                            <ListItemText primary={menu?.label} className="menu_label fontFamily" />
                                            {/* Show expand/collapse icon if menu has submenus */}
                                            {menu?.hasMoreMenu ? menu?.isExpanded ? <ExpandLess /> : <ExpandMore /> : ''}
                                        </ListItemButton>

                                        {/* Submenu */}
                                        {menu?.expandArray && handleSidebar(menu)}
                                    </>
                                )}
                            </React.Fragment>
                        )
                    })}
                </List>
            </div>
        </>
    )
}

export default LeftContent