import React, {useCallback, useEffect, useRef, useState} from "react";
import FilledButton from "../../../../../Components/FileButton";
import {Box} from "@mui/material";
import {useDispatch} from "react-redux";
import SimpleReactValidator from "simple-react-validator";
import {addHelpAndSupportEmail} from "../../../../../Redux/HelpAndSupport/action";
import {createTagLineSplashScreen} from "../../../../../Redux/Master/action";


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 486,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: "5px",
};

const AddTagLineSplashScreen = ({ modalValue, handleOpenModal, redirectApiHandler }) => {
    const dispatch = useDispatch();
    const [loader, setLoader] = useState(false);
    const simpleValidator = useRef(new SimpleReactValidator());
    const [, updateState] = useState({});
    const forceUpdate = useCallback(() => updateState({}), []);
    const [formData, setFormData] = useState({ tagLine: '' });

    const handleSubmit = (e) => {
        e.preventDefault();
        if (simpleValidator.current.allValid()) {
            setLoader(true)
            dispatch(createTagLineSplashScreen(formData)).then(res => {
                if (res.data.success) {
                    setLoader(false)
                    redirectApiHandler()
                    handleOpenModal('CommonPop', { header: "Success", body: res?.data?.message })
                } else {
                    setLoader(false)
                    handleOpenModal('CommonPop', { header: "Error", body: res?.data?.message || res?.data?.msg })
                }
            })
        } else {
            simpleValidator.current.showMessages();
            forceUpdate();
        }
    }

    useEffect(() => {
        if (modalValue?.isEdit) {
            setFormData({ ...formData, tagLine: modalValue?.row?.tagLine })
        }
    }, [modalValue]);
    return(
        <Box sx={style}>
        <div className={'add_admin_user_popup modal_main_popup add_avatar_section lobby_section_details'}>
            <div className={'modal_popup_title'}>
                <h2>{modalValue?.isEdit ? "Update Tag Line" : ' Add Tag Line'}</h2>
            </div>
            <div className={'add_admin_user_popup_content'}>
                <form onSubmit={(e) => handleSubmit(e)}>
                    <div className={'user_kyc_section'}>
                        <div className={'user_kyc_section_filed'}>
                            <label>Tag Line <span className={'validation-star'}>*</span></label>
                            <div className={'user_kyc_section_input_filed lobby-type-description'}>
                                <input type={'text'} maxLength={40} value={formData?.tagLine} placeholder={'Enter Tag Line'} onChange={(e) => setFormData({ ...formData, tagLine: e.target.value })} />
                                <span>{formData?.tagLine?.length}/40</span>
                            </div>
                            {simpleValidator.current.message("tagLine", formData?.tagLine, 'required|min:2')}
                        </div>
                    </div>
                    <div className={'formData_btn'}>
                        <button className={'btn_default'} type={'reset'} onClick={() => handleOpenModal()}>Cancel</button>
                        <FilledButton type={'submit'} value={modalValue?.isEdit ? 'Update' : 'Save'} className={'btn loader_css'} loading={loader} />
                    </div>
                </form>
            </div>
        </div>
    </Box>
    )
}
export default AddTagLineSplashScreen