import React, {useEffect, useRef} from "react";
import ReactPlayer from 'react-player'

function Clip({ url }) {
    const videoRef = useRef();

    // useEffect(() => {
    //     if(videoRef?.current){
    //         videoRef?.current?.load();
    //         videoRef?.current?.play();
    //     }
    //
    // }, [url]);

    useEffect(() => {
        if (videoRef?.current) {
            const videoElement = videoRef.current;

            videoElement.addEventListener('loadedmetadata', handleVideoLoaded);
            videoElement.load();

            return () => {
                videoElement.removeEventListener('loadedmetadata', handleVideoLoaded);
            };
        }
    }, [url]);

    const handleVideoLoaded = () => {
        videoRef?.current?.play();
    };

    function youtube_parser(url){
        var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
        var match = url.match(regExp);
        return (match&&match[7].length === 11)? match[7] : false;
    }

    return (
        <>
            {
                youtube_parser(url) ?
                    <ReactPlayer url={url}  width="100%" height="100%" />
                    :
                    <video ref={videoRef} width="100%" height="240" controls muted>
                        <source src={url} />
                    </video>
            }
            </>
    );
}
export default Clip