import axios from "axios";
import Cookies from 'js-cookie';
const instance = axios.create();

const axiosClient = axios.create({
  baseURL: "https://backend.ludo-cms-frontend.superify.in",
});

instance.defaults.headers.common = { "Access-Control-Allow-Origin": "https://backend.ludo-cms-frontend.superify.in" };

axiosClient.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    let res = error.response;
    if (res.status === 401 || error.response?.data?.message === "Token Expired") {
      Cookies.remove("token", { path: "/"});
      Cookies.remove("user_data", { path: "/"});
      window.location.reload();
    }
    console.error("Looks like there was a problem. Status Code:" + res.status);
    return Promise.reject(error);
  }
);

axiosClient.interceptors.request.use(function (config) {
  const token = Cookies.get("token");
  config.headers["Authorization"] = token || "";
  return config;
});

export default axiosClient;
