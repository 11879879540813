import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import { Box, Button } from "@mui/material";
import { useDispatch } from "react-redux";
import FilledButton from "../../../FileButton";
import { updateStatusAdminUserList } from "../../../../Redux/AdminUser/action";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: "5px",
};

const ActiveUserModal = ({ modalValue, handleOpenModal, redirectApiHandler }) => {
    const dispatch = useDispatch();
    const [loader, setLoader] = useState(false);

    const handleSubmit = (event) => {
        event.preventDefault();
        setLoader(true);
        dispatch(updateStatusAdminUserList(modalValue)).then(res => {
            if (res?.data?.success) {
                redirectApiHandler();
                setLoader(false);
                dispatch({ type: 'UPDATE_SNACKBAR_STATE', payload: { open:true, message: res?.data?.message, variant:"success", title:"Success" } })
                handleOpenModal()
                // handleOpenModal('CommonPop', { header: "Success", body: res?.data?.message });
            } else {
                setLoader(false);
                dispatch({ type: 'UPDATE_SNACKBAR_STATE', payload: { open:true, message: res?.data?.message || res?.data?.msg, variant:"error", title:"Error" } })
                handleOpenModal()
                // handleOpenModal('CommonPop', { header: "Info", body: res?.data?.message || res?.data?.msg });
            }
        })
    };

    return (
        <Box sx={style} className={'user_popup_section'}>
            <div className='d_flex justify_content_center'>
                <Typography id="modal-modal-title" variant="h6" component="h2" className={"block-user-title"}>
                    {
                        modalValue?.isEnableCate ?
                            'Do you want to  Enable the user?'
                            :
                            `Do you want to ${!modalValue?.isBlock ? 'activate' : 'deactivate'} the admin?`
                    }
                </Typography>
            </div>
            <div className='publisher_popup'>
                <form onSubmit={(e) => handleSubmit(e)}>
                    <div className='form_main'>
                        <Box mb={3} className={"publisher_popup_box"}>
                            <button className={'btn_default'}  type='reset' onClick={() => handleOpenModal()}> NO </button>
                            <FilledButton type={'submit'} value={'Yes'} className={'btn loader_css'} loading={loader} />
                        </Box>
                    </div>
                </form>
            </div>
        </Box>
    )
}
export default ActiveUserModal